footer {
  background-color: #141517;
  color: white;
  padding: 1rem;
  padding-top: 40px !important;
}

.title {
  margin: 5rem 7rem;
  color: royalblue;
  border-bottom: 1px solid;
}

footer a {
  color: white;
  transition: color 0.4s;
}

footer a:hover {
  color: royalblue;
  text-decoration: none;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-main {
  display: flex;
  margin-bottom: 4rem;
}

.footer-column {
  flex-basis: 200px;
  flex-grow: 1;
  text-align: center;
}

.footer-heading {
  margin-bottom: 1rem;
}

.footer-link {
  display: block;
  width: max-content;
  padding: 5px 10px;
  margin: 0 auto;
}

.footer-bottom {
  display: flex;
}

.copyright {
  margin-right: auto;
}

.footer-social {
  margin-right: auto;
}
